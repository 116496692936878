/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveImage } from "@dataResolvers";
import { InitiativesIndex as PageTemplate } from "@pageTemplates";

export const query = graphql`
  query InitiativesIndexQuery {
    craft {
      entry(section: "majorInitiativesIndex") {
        title
        url
        slug
        ... on Craft_majorInitiativesIndex_majorInitiativesIndex_Entry {
          #meta
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          #hero
          heroLabel
          heroTitle
          heroImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
        }
      }
      entries(section: "majorInitiatives") {
        ... on Craft_majorInitiatives_initiatives_Entry {
          id
          commonIcon {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          title
          commonSummary
          url
        }
      }
    }
  }
`;

const previewQuery = `query InitiativesIndexQuery {
  craft {
    entry(section: "majorInitiativesIndex") {
      title
      url
      slug
      ... on Craft_majorInitiativesIndex_majorInitiativesIndex_Entry {
        #meta
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        #hero
        heroLabel
        heroTitle
        heroImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
      }
    }
    entries(section: "majorInitiatives") {
      ... on Craft_majorInitiatives_initiatives_Entry {
        id
        commonIcon {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        title
        commonSummary
        url
      }
    }
  }
}`;

const dataResolver = ({ craft }) => {
  const {
    title,
    url,
    slug,
    // meta
    metaTitle,
    metaDescription,
    metaImage,
    // hero
    heroLabel,
    heroTitle,
    heroImage,
  } = craft.entry;

  const inits = craft.entries;

  const resolveInits = _inits =>
    _inits.map(init => {
      return {
        id: init.id,
        heading: init.title,
        copy: init.commonSummary,
        image: resolveImage(init.commonIcon),
        link: {
          text: init.commonExternalLink?.customText || "Learn More",
          url: init.commonExternalLink?.url || init.url,
        },
      };
    });

  return {
    title,
    hero: {
      label: heroLabel,
      heading: heroTitle,
      image: resolveImage(heroImage),
    },
    inits: {
      cards: resolveInits(inits),
    },
    meta: {
      title,
      url,
      slug,
      metaTitle,
      metaDescription,
      metaImage: resolveImage(metaImage || heroImage),
    },
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
    site: process.env.GATSBY_CRAFT_SITE_HANDLE,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
